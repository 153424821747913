
import dayjs from 'dayjs'
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      dayjs,
    }
  },
}
