
export default {
  data: () => ({
    datas: [],
    isLoading: true,
    size: 10,
  }),
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      const res = await this.$services.news.getNewsFeatured({
        size: this.size,
      })
      if (res.success) {
        this.datas = res.data
      } else {
        this.datas = []
      }
      this.isLoading = false
    },
  },
}
