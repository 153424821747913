import { render, staticRenderFns } from "./HomeNews.vue?vue&type=template&id=f9af8436&scoped=true&"
import script from "./HomeNews.vue?vue&type=script&lang=js&"
export * from "./HomeNews.vue?vue&type=script&lang=js&"
import style0 from "./HomeNews.vue?vue&type=style&index=0&id=f9af8436&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9af8436",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/usr/src/app/src/components/base/containers/Spinner.vue').default,HomeNewsListDesktop: require('/usr/src/app/src/components/home/components/HomeNewsListDesktop.vue').default,HomeNewsListMobile: require('/usr/src/app/src/components/home/components/HomeNewsListMobile.vue').default})
