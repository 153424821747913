
export default {
  components: {
    // carousel: () => (process.client ? import('vue-owl-carousel') : ''),
    carousel: () => import('vue-owl-carousel'),
  },
  props: {
    dataNews: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    items: 2.7,
  }),
}
